import { CurrencyAlphaCode } from '../types/currency.types';

const ACCOUNT_COLORS: Record<CurrencyAlphaCode, string> = {
  [CurrencyAlphaCode.UZS]: '#0099B5',
  [CurrencyAlphaCode.USD]: '#0A7A00',
  [CurrencyAlphaCode.EUR]: '#005CA8',
  [CurrencyAlphaCode.GBP]: '#7945CD',
  [CurrencyAlphaCode.CHF]: '#D21904',
  [CurrencyAlphaCode.RUB]: '#4070DF',
  [CurrencyAlphaCode.KZT]: '#00B9E1',
  [CurrencyAlphaCode.TRY]: '#D21904',
  [CurrencyAlphaCode.CNY]: '#D21904',
  [CurrencyAlphaCode.JPY]: '#E1B485',
  [CurrencyAlphaCode.AED]: '#C1B597'
};

export const COLORS = {
  // Surface colors
  surfacePrimary: '#ffffff',
  surfaceSecondary: '#ffffff',

  // Container colors
  containerPrimary: '#ffffff',
  containerSecondary: '#f3f4f6',

  // Text colors
  textPrimary: '#101010',
  textSecondary: '#808080',
  textTertiary: '#999999',
  textQuaternary: '#ffffff',
  textDisabled: '#c2c2c2',
  textBrand: '#7000FF',

  // Element colors
  elementsPrimary: '#101010',
  elementsSecondary: '#ffffff',
  elementsTertiary: '#f3f4f6',
  elementsQuaternary: '#e7e8ea',
  elementsDarkQuinary: '#919396',
  elementsQuinary: '#c5c7ca',
  elementsSenary: '#4e4d4d',
  elementsGray: '#F9F9FA',

  // Static colors
  staticBlack: '#101010',
  staticWhite: '#ffffff',
  staticRed: '#f24835',
  staticGreen: '#09b849',
  staticErrorAccent: '#cb0000',
  staticPurple: '#7000ff',
  staticYellow: '#f9de6d',
  staticOrange: '#F48C2C',

  // Brand colors
  brand4: '#54B8F9',
  brand150: '#F8F2FF',
  brand1500: '#7000ff',
  brand1100: '#EAE2FF',
  brand1300: '#BBA2FD',

  brand350: '#F1F9DF',
  brand3300: '#DCF0AF',

  brand550: '#FFF3F4',
  brand5100: '#FAD6D9',

  inherit: 'inherit',
  transparent: 'transparent',

  placeholder: '#55585D',
  accountIcon: ACCOUNT_COLORS
} as const;
