import {
  IResponseData,
  PaymentCard,
  PaymentCardMapped } from '@/shared/types';
import { detectCardType } from '../functions/currency';

export const mapClientCardsResponse = (data: IResponseData<PaymentCard>): IResponseData<PaymentCardMapped> => {
  return {
    ...data,
    items: data?.items.map((v: PaymentCard) => {
      return {
        ...v,
        label: `${v.maskedCard} - ${v.accountName}`,
        value: {
          maskedPAN: v.maskedCard?.replace(/\s/g, ''),
          cardAccount: v.cardAccountNumber
        },
        maskedCard: `${v.maskedCard} - ${v.embossedChName}`,
        cardType: detectCardType(v.maskedCard)
      };
    }) || []
  } as IResponseData<PaymentCardMapped>;
};
