import { VNode } from 'vue';
import { Value } from 'naive-ui/es/date-picker/src/interface';
import { BoundFace } from '@/shared/types/bound-face.ts';
import { PLATFORM_KEY_TYPE } from '../utils/constants/global.config';
import { DirectionType } from '.';
import { DocumentSource } from './common.types';
import { ICurrency } from './currency.types';

export enum DocumentStatus {
  NotDefined, // Не определен
  Created, // Создан
  AuthorizedForVerification, // Авторизован на проверку
  Verified, // Проверен
  Authorized, // Авторизован
  DocumentProcessing, // Документ на обработке
  EnteredInBank, // Введен в банке
  ApprovedByBank, // Утвержден в банке
  HelInBank, // Проведен в банке
  RejectedInBank, // Забракован в банке
  DeletedInBank, // Удален в банке
  RejectedTCR, // Забракован ТЦР (банк)
  ConfirmedInBank, // Подтвержден в банке
  OnExecution, // На исполнении
  SentToGO_Bank, // Отправлен в ГО (банк)
  DepositedInBank = 16, // Отложен в банке
  SignatureIsNotCorrect = 19, // Подпись не верна
  CancelledByUser, // Аннулирован пользователем
  ProcessedByTheMobileApp = 25, // Обрабатывается мобильным приложением
  ConductedByANOR, // Проведен ANOR	1
  TransactionIsAwaitingConfirmation_ANOR, // Транзакция ожидает подтверждения (АНОР)
  RejectedByANOR, // Отклонен ANOR
  TransferredToBankMail, // Передан в BankMail
}

export const enum DirectionSymbol {
  'IN' = '+',
  'OUT' = '-',
  'BETWEEN' = ''
}

export interface IDocumentType {
  id: number;
  code: string;
  name: string;
}

export enum EDocumentType {
  PaymentOrder = '01', // Платежное поручение на счет
  Replenishment = '97', // Платежное поручение пополнение ПК
  Treasury = '98',
  Budget = '99',
}

export enum ECardType {
  ToCard = 1,
  ToOther = 0,
}

export interface IDocumentTopTableOption {
  label: string;
  name: string;
  classes?: string[];
  loading?: boolean;
  disabled?: boolean;
  keyName?: string | string[];
  type?:
    | 'default'
    | 'tertiary'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error';
  tooltip?: string;
  routeMetaKeyName?: string;
  renderIcon?: () => VNode;
}

export interface IDocument {
  id?: string;
  name?: string | null;
  templateId?: string | null;
  templateName?: string | null;
  documentType?: EDocumentType | null;
  documentTypeName?: string | null;
  senderAccountNumber?: string | null;
  paymentNumber?: string | null;
  stateName?: string;
  amount: number | string | null;
  receiverName?: string | null;
  receiverAccountNumber?: string | null;
  businessId?: number;
  documentDate?: string | number;
  receiverBranch?: string | null;
  receiverBranchName?: string | null;
  receiverInnOrPinfl?: string | null;
  paymentPurpose?: string | null;
  details?: string | null;
  paymentPurposeCode?: string | null;
  purposeCodeName?: string | null;
  isAnor?: boolean;
  cardBranch?: string | null;
  cardAccount?: string | null;
  cardNumber?: string | null;
  embossedChName?: string | null;
  budgetInn?: string | null;
  budgetAccountNumber?: string | null;
  state?: DocumentStatus | null;
  textToSign?: string;
  budgetName?: string;
  senderName?: string;
  cardType?: number;
  senderBranch?: string | null;
  senderInn?: string | null;
  senderInnOrPinfl?: string | null;
  senderBranchName?: string | null;
  direction?: DirectionType;
  source?: DocumentSource;
  provedDate?: string | null;
  inputDate?: string | null;
  epsStateName?: string | null;
  deletedDate?: string | null;
}

export type IDocumentTemplate = Omit<IDocument, 'stateName' | 'businessId'> & {
  name: string | null;
  description: string | null;
  senderBusinessCode: null;
  senderBranch: string | null;
  purposeCodeName?: string | null;
};

export interface IFormNewDocument {
  id?: string | null;
  name?: string | null;
  templateId?: string | null;
  templateName?: string | null;
  numberId?: string | null;
  dateFrom?: number | null;
  dateTo?: number | null;
  description?: string | null;
  documentType: EDocumentType | null;
  documentNumber?: string | null;
  paymentNumber?: string | null;
  documentDate?: Value | null;
  state?: number | null;
  stateName?: string | null;
  inputDate?: string | null;
  provedDate?: string | null;
}
export interface IFormDebitDocument {
  senderAccountNumber?: string | null;
  senderBranch?: string | null;
  senderBranchName?: string | null;
  senderBranchCode?: string | null;
  senderInn?: string | null;
  senderName?: string | null;
  direction?: DirectionType | null;
  senderPinfl?: string | null;
}

export type SenderBranchSelectType = Pick<
  IFormDebitDocument,
  'senderBranch' | 'senderBranchName'
> & {
  value?: string;
  label?: string;
};

export interface IFormCreditDocument {
  receiverBranch: string | null | undefined;
  receiverBranchName?: string | null;
  receiverBranchCode?: string | null;
  receiverAccountNumber: string | null;
  receiverName?: string | null;
  receiverInn?: string | null;
  receiverInnOrPinfl?: string | null;
  budgetAccountNumber?: string | null;
  budgetInn?: string | null;
  cardBranch?: string | null;
  cardAccount?: string | null;
  cardNumber?: string | null;
  embossedChName?: string | null;
  maskedCard?: string | null;
  budgetName?: string | null;
  direction?: DirectionType | null;
  cardType?: ECardType | null;
}

export interface IFormOtherDocument {
  amount: number | string | null;
  purposeCodeName?: string | null;
  paymentPurposeCode?: string | null;
  paymentPurpose: string | null;
}

export interface IDocumentForm {
  newDocument: IFormNewDocument;
  debit: IFormDebitDocument;
  credit: IFormCreditDocument;
  others: IFormOtherDocument;
}

export type IFormNewTemplate = Omit<IFormNewDocument, 'documentNumber'>;

export interface IDocumentTemplateForm {
  newTemplate: IFormNewTemplate;
  debit: IFormDebitDocument;
  credit: IFormCreditDocument;
  others: IFormOtherDocument;
}

export interface IPaymentOrderTemplatePayload
  extends IFormNewDocument,
    IFormDebitDocument,
    IFormCreditDocument,
    IFormOtherDocument {
  senderBusinessCode?: string;
  isAnor?: boolean;
}

export interface IPaymentOrderTemplateParams {
  paymentId?: string;
  payload: IPaymentOrderTemplatePayload;
  documentType: EDocumentType;
  businessCode: string;
  branch: string;
  cardType?: boolean;
}

export const enum PaymentHistoryState {
  Postponed = -1,
  Error,
  Introduced,
  Conducted,
  InProgress
}

export const enum PaymentHistoryCondition {
  Reject = -1,
  Signing,
  Waiting,
  Success,
}

export interface IPaymentHistory {
  id: number,
  documentDate: string,
  documentType: string,
  documentTypeName: string,
  amount: number,
  currency: ICurrency,
  details: string,
  receiverBranch: string,
  receiverAccountNumber: string,
  receiverName: string,
  textToSign: null | string,
  state: PaymentHistoryState,
  stateName: string,
  senderAccountNumber: string,
  senderName: string,
  direction: DirectionType,
  isAnor: boolean,
  source: DocumentSource,
  statusAppDesc: null | string,
  condition: PaymentHistoryCondition;
}

export interface INextPaymentNumber {
  nextDocNumber: string;
}

export interface IPaymentHistoryItem {
  source: string | null;
  operationDate: string | null;
  userLogin: string | null;
  userName: string | null;
  ipAddress: string | null;
  action: string | null;
}

export interface IPaymentHistoryRequestParams {
  source: number;
}

export interface PaymentDocument extends Omit<IDocument, 'id' | 'textToSign'> {
  id: string | number;
  textToSign: string;
  documentDate: string;
  amount: number;
}

export interface CreateDocumentPayload {
  documentType: string,
  branch: string,
  businessCode: string,
  applicationId: number,
  creditAmount: number,
  email: string,
  appPhoneNumber: string,
  contactPhoneNumber: string,
  relatedPersons: BoundFace[] | null,
  commentary: string | null
}

export interface CreateDocumentDTO {
  documentType: string,
  id: string,
  fileName: string,
  createdAt: string,
  createdBy: string,
  signature: null | string,
  hash: string,
}

export interface SignDocumentPayload {
  branch: string;
  businessCode: string;
  signature: string;
  keyType: PLATFORM_KEY_TYPE;
  documentId: string | number;
  signType?: SignTypes
}

export const enum SignTypes {
  BASE64,
  HASH
}

export interface SignedDocumentSignature {
  id: string;
  fileName: string;
  signedAt: string;
  signedBy: string;
}

export interface SignedDocument {
  documentType: string;
  id: string;
  fileName: string;
  createdAt: string;
  createdBy: string;
  signature: SignedDocumentSignature;
  stampedDocument: Pick<SignedDocumentSignature, 'id' | 'fileName'>;
  additionalData: string;
}
