import { CardType, CardTypeRange } from '@/shared/types/currency.types';

export const PENNY_IN_SUM = 100;
export const COURSE_MULTIPLIER = 100;

export const cardTypeRanges: CardTypeRange[] = [
  {
    ranges: [
      { subsequence: [ 8600, 8600 ] },
      { subsequence: [ 5614, 5614 ] }
    ],
    type: CardType.UzCard
  },
  {
    ranges: [
      { subsequence: [ 9860, 9860 ] }
    ],
    type: CardType.Humo
  },
  {
    ranges: [
      { subsequence: [ 4000, 4999 ] }
    ],
    type: CardType.Visa
  },
  {
    ranges: [
      { subsequence: [ 51, 55 ] },
      { subsequence: [ 2221, 2720 ] }
    ],
    type: CardType.MasterCard
  },
  {
    ranges: [
      { subsequence: [ 60, 60 ] },
      { subsequence: [ 62, 62 ] }
    ],
    type: CardType.UnionPay
  }
];
