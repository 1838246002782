import { CardRange, CardType } from '@/shared/types/currency.types';
import { cardTypeRanges } from '../constants/currency';

function isMatchingRange(ranges: CardRange[], firstTwoDigits: number, firstFourDigits: number): boolean {
  return ranges.some(({ subsequence }) => {
    const minLength = subsequence[0].toString().length; // Длина числа (2 или 4)
    const prefix = minLength === 2 ? firstTwoDigits : firstFourDigits;

    return prefix >= subsequence[0] && prefix <= subsequence[1];
  });
}

export function detectCardType(maskedCard?: string | null): CardType {
  if (!maskedCard) return CardType.Unknown;

  const firstTwoDigits = parseInt(maskedCard.substring(0, 2));
  const firstFourDigits = parseInt(maskedCard.substring(0, 4));

  const matchedType = cardTypeRanges.find(({ ranges }) => isMatchingRange(ranges, firstTwoDigits, firstFourDigits))?.type;

  return matchedType ?? CardType.Unknown;
}
