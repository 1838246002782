import type { FormItemRule } from 'naive-ui';
import i18n from '@/shared/plugins/i18n';
import { INN_LENGTH, INN_OR_PINFL_LENGTH, PHONE_NUMBER_LENGTH } from '@/shared/utils/constants/common';
import { regExp as regExpConstants } from '@/shared/utils/constants/regular-expressions';
import {
  getRuleError,
  isCardNumberCorrect,
  isInnCorrect,
  isPassportCorrect,
  isPinflCorrect,
  isReceiverAccountNumberCorrect
} from '@/shared/utils/index';
import { trimBetween } from './functions';

interface IReceiverAccountNumberParams {
  receiverAccount: string | null;
  receiverBranch?: string | null;
  senderAccount?: string | null;
}
interface IRegExpStrItem {
  value: string;
  flags?: string;
}
interface IRegExpStr {
  readonly [key: string]: Readonly<IRegExpStrItem>;
}
interface IValidation {
  readonly [key: string]: (v: string) => boolean;
}

export const regExpStr: IRegExpStr = {
  space: {
    value: '\\s+'
  },
  letterOrNumber: {
    value: '^([A-ZА-Я0-9]+)$',
    flags: 'i'
  },
  string: {
    value: '[a-zA-Zа-яА-Я]',
    flags: 'i'
  },
  number: {
    value: '\\d+'
  },
  fullNumber: {
    value: '^\\d+$'
  },
  email: {
    value:
      '^(?=.{1,254}$)(?=.{1,64}@)[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:(?!-)[A-Za-z0-9-]+(?<!-)\\.)+[A-Za-z]{2,}$'
  },
  tag: {
    value: '(<([^>]+)>)',
    flags: 'gi'
  },
  allowedSymbols: {
    value: '[?;:`\',.\\[\\]\\/\\-_=+!@#№$%^~*(){}]',
    flags: 'giu'
  },
  latin: {
    value: '[A-Za-z]+$',
    flags: 'g'
  },
  noLatinSymbols: {
    value: '^[^a-z]+$',
    flags: 'i'
  },
  noCyrillicSymbols: {
    value: '^[^а-яё]+$',
    flags: 'i'
  }
};

interface IRegExp {
  [key: string]: RegExp;
}

export const regExp: IRegExp = {};

for (const key in regExpStr) {
  if (Object.prototype.hasOwnProperty.call(regExpStr, key)) {
    regExp[key] = new RegExp(regExpStr[key].value, regExpStr[key]?.flags);
  }
}

export function ruleRequired(_: object, value: string | number | null) {
  if (!value?.toString()?.trim?.()) {
    return new Error(i18n.global.t('validation.required'));
  }
  return true;
}

export function ruleReceiverAccountNumberValid({
  receiverAccount,
  receiverBranch,
  senderAccount
}: IReceiverAccountNumberParams) {
  if (!receiverAccount) return true;

  if (
    isReceiverAccountNumberCorrect(
      receiverAccount,
      receiverBranch,
      senderAccount
    )
  ) {
    return true;
  }

  return new Error(i18n.global.t('validation.receiverAccountNumberInvalid'));
}

export function ruleCorporateReceiverAccountNumberValid({
  receiverAccount,
  receiverBranch,
  senderAccount
}: IReceiverAccountNumberParams) {
  if (!receiverAccount) return true;

  if (!/^2310[24]\d{15}$/.test(receiverAccount || '')) {
    return new Error(i18n.global.t('validation.receiverAccountNumberInvalid'));
  }

  return ruleReceiverAccountNumberValid({
    receiverAccount,
    receiverBranch,
    senderAccount
  });
}

export function ruleOtherReceiverAccountNumberValid({
  receiverAccount,
  receiverBranch,
  senderAccount
}: IReceiverAccountNumberParams) {
  if (!receiverAccount) return true;

  if (!/^23120\d{15}$/.test(receiverAccount || '')) {
    return new Error(i18n.global.t('validation.receiverAccountNumberInvalid'));
  }

  return ruleReceiverAccountNumberValid({
    receiverAccount,
    receiverBranch,
    senderAccount
  });
}

export function ruleReceiverInnOrPinflValid(_: object, value: string) {
  if (value?.length === INN_LENGTH) {
    // Inn
    if (isInnCorrect(value)) {
      return true;
    }

    return new Error(i18n.global.t('validation.innInvalid'));
  } else if (value?.length === INN_OR_PINFL_LENGTH) {
    // Pinfl
    if (isPinflCorrect(value)) {
      return true;
    }

    return new Error(i18n.global.t('validation.pinflInvalid'));
  }

  return new Error(i18n.global.t('IIN-contains-9-digits-and-PINFL-14'));
}

export function rulePinflOrPassportValid(options: FormItemRule, value: string): boolean | Error {
  const isPassport = regExpConstants.latin.test(value);

  if (isPassport) {
    return isPassportCorrect(trimBetween(value)) || getRuleError(options, 'serial-number-passport-non-valid');
  }

  return isPinflCorrect(value) || getRuleError(options, 'pinfl-non-valid');
}

export function ruleBudgetAccountNumberValid(_: object, value: string) {
  if (!/^\d{27}$/.test(value)) {
    return new Error(i18n.global.t('validation.budgetAccountNumberInvalid'));
  }
  return true;
}

export function ruleTreasuryAccountNumberValid(_: object, value: string) {
  if (!/^\d{25}$/.test(value)) {
    return new Error(i18n.global.t('validation.treasuryAccountNumberInvalid'));
  }
  return true;
}

export function ruleCardNumberValid(_: object, value: string) {
  value = trimBetween(value);
  if (!/^\d{16}$/.test(value)) {
    return new Error(i18n.global.t('validation.cardNumberLength'));
  }
  if (!isCardNumberCorrect(value)) {
    return new Error(i18n.global.t('validation.cardNumberInvalid'));
  }
  return true;
}

export function rulePreventCyrillic(_: object, value: string) {
  return !/[а-яё]/i.test(value);
}

export function rulePreventSpaces(_: object, value: string) {
  return !/\s/.test(value);
}

export function ruleConfirmPassword({
  password,
  confirmPassword
}: {
  password: string | null;
  confirmPassword: string | null;
}) {
  return password?.toLowerCase() === confirmPassword?.toLowerCase();
}

export const ruleExactLength = (
  _: object,
  value: string,
  exactLength: number
): true | Error =>
  value.length === exactLength
    ? true
    : new Error(i18n.global.t('Only allowed chars', { n: exactLength }));

export const ruleLengthWithMessage = (
  options: FormItemRule,
  value: string
): boolean | Error => {
  const length = (options.len ?? 0);
  const trimmedValue = trimBetween(value);

  if (!trimmedValue) return true;

  return trimmedValue.length === length || getRuleError(options, 'Only allowed chars', { n: length });
};

export const ruleMaxAmount = (
  _: object,
  value: string,
  amount: number
): boolean | Error => {

  return +value > amount
    ? new Error(i18n.global.t('Insufficient-funds'))
    : true;
};

export const ruleMinAmount = (
  options: FormItemRule,
  value: string
): boolean | Error => {
  return !(+value < (options.min || 0)) || getRuleError(options, 'insufficient-amount');
};

export const ruleMaxAmountWithMessage = (
  options: FormItemRule,
  value: string
): boolean | Error => {
  return !(+value > (options.max ?? Infinity)) || getRuleError(options, 'Insufficient-funds');
};

export const ruleConfirm = (
  options: FormItemRule,
  value: string | boolean
): boolean | Error => {
  return !!value || getRuleError(options, 'validation.required');
};

export const ruleMaxLength = (
  _: object,
  value: string,
  maxLength: number
): boolean | Error =>
  value.length <= maxLength
    ? true
    : new Error(i18n.global.t('Chars max length', { n: maxLength }));

export const ruleEmail = (_: object, email: string)
  : boolean | Error => {
  if (email) {
    return regExp.email.test(email)
      ? true
      : new Error(i18n.global.t('incorrect-email'));
  }
  return true;
};

export const rulePhoneNumberWithMask = (_: object, value: string): boolean | Error => (
  value.length === PHONE_NUMBER_LENGTH
    ? true
    : new Error(i18n.global.t('Enter-the-number-in-the-correct-format'))
);

export const combineRules = (...ruleResults: Array<boolean | Error>): boolean | Error => {
  const hasError = ruleResults.find(value => typeof value !== 'boolean');
  return hasError || true;
};

export const validate: IValidation = {
  fullNumber: (v) => !v || regExp.fullNumber.test(v),
  number: (v) => !v || regExp.number.test(v),
  string: (v) => !v || regExp.string.test(v),
  letterOrNumber: (v) => !v || regExp.letterOrNumber.test(v),
  latin: (v) => !v || regExp.latin.test(v),
  noLatinSymbols: (v) => !v || regExp.noLatinSymbols.test(v),
  noCyrillicSymbols: (v) => !v || regExp.noCyrillicSymbols.test(v)
};
